<template>
  <div class="content profile-content">
    <b-modal id="psych-student" hide-footer hide-header centered size="lg">
      <div class="content">
        <div v-for="psych in psychData">
          <div class="profile-student__title">
            {{ $t("profile.children.consultations-result") }}
          </div>

          <div class="psych-student-table table-responsive-md">
            <table class="table table-striped">
              <tbody>
              <tr>
                <td scope="row" class="">{{ $t('profile.children.consultations-date') }}</td>
                <td class="">{{ psych.date }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.time') }}</td>
                <td>{{ psych.time }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.psychologist-name') }}</td>
                <td>{{ psych.psychologist_name.name }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.comment-user') }}</td>
                <td>{{ psych.comment_user }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.consultations-comments') }}</td>
                <td>{{ psych.comment_psychologist }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.recommendation') }}</td>
                <td>{{ psych.recommendation }}</td>
              </tr>
              <tr>
                <td scope="row">{{ $t('profile.children.visit-purpose') }}</td>
                <td>{{ psych.visit_purpose }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button class="btn result-close" @click="$bvModal.hide('psych-student')">{{
          $t('profile.results.btn-close')
        }}
      </button>
    </b-modal>
    <b-modal id="profile-student" hide-footer hide-header centered size="lg">
      <div class="content">
        <div class="profile-student__title">
          {{ $t("profile.title") }}
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="form-group">
              <label class="form-label">{{ $t('profile.iin-label') }}</label>
              <el-input :placeholder="this.studentInfo.iin" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('profile.last-name-label') }}</label>
              <el-input :placeholder="this.studentInfo.last_name" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('profile.first-name-label') }}</label>
              <el-input :placeholder="this.studentInfo.first_name" disabled="disabled"></el-input>
            </div>
            <div v-if="this.studentInfo.patronymic" class="form-group">
              <label class="form-label">{{ $t('profile.patronymic-label') }}</label>
              <el-input :placeholder="this.studentInfo.patronymic" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.gender') }}</label>
              <el-input v-if="this.studentInfo.sex==1" :placeholder="$t('references.gender-male')"
                        disabled="disabled"></el-input>
              <el-input v-else :placeholder="$t('references.gender-female')" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('profile.birth-date-label') }}</label>

              <el-date-picker
                  disabled
                  style="width: 100%;"
                  v-model="this.studentInfo.birth_date"
                  type="date"
                  placeholder="Дата"
                  format="yyyy.MM.dd"
                  value-format="yyyy.MM.dd">
              </el-date-picker>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.email-label') }}</label>
              <el-input :placeholder="this.studentInfo.email" disabled="disabled"></el-input>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="form-group">
              <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
              <el-input
                  :placeholder="'+'+this.studentInfo.phone" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.region-label') }}</label>
              <el-input :placeholder="this.studentRegion" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.district-label') }}</label>
              <el-input :placeholder="this.studentCity" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.locality-label') }}</label>
              <el-input :placeholder="this.studentLocality" disabled="disabled"></el-input>
            </div>

            <h4 class="form-sub-title">{{ $t('profile.education') }}</h4>
            <div class="form-group">
              <label class="form-label">{{ $t('references.school-name-label') }}</label>

              <el-select
                  disabled
                  :placeholder="$t('references.school-name-placeholder')"
                  v-model="this.studentSchool"
                  filterable>
                <el-option
                    v-for="item in schools"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.class-label') }}</label>
              <el-input :placeholder="this.studentClass" disabled="disabled"></el-input>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="form-group">
              <label class="form-label">{{ $t('references.education-language-label') }}</label>
              <el-input v-if="this.studentInstructionLanguage=='ru'" :placeholder="$t('references.language-ru')"
                        disabled="disabled"></el-input>
              <el-input v-else :placeholder="$t('references.language-kz')" disabled="disabled"></el-input>
            </div>
            <div class="form-group">
              <label class="form-label">{{ $t('references.test-language-label') }}</label>
              <el-input v-if="this.studentTestLanguage=='ru'" :placeholder="$t('references.language-ru')"
                        disabled="disabled"></el-input>
              <el-input v-else :placeholder="$t('references.language-kz')" disabled="disabled"></el-input>
            </div>




            <div v-if="this.studentInfo.parent!=null">
            <h4  class="form-sub-title">{{ $t('sidebar.parent') }}</h4>
            <div class="form-group">
              <label class="form-label">{{ $t('profile.fio') }}</label>
              <el-input :placeholder="this.studentInfo.parent.last_name+' '+this.studentInfo.parent.first_name+' '+this.studentInfo.parent.patronymic" disabled="disabled"></el-input>
            </div>
            <div v-if="this.studentInfo.parent.phone" class="form-group">
              <label class="form-label">{{ $t('profile.representative-tel-label') }}</label>
              <el-input
                  :placeholder="this.studentInfo.parent.phone" disabled="disabled"></el-input>
            </div>
            <div v-if="this.studentInfo.parent.email" class="form-group">
              <label class="form-label">{{ $t('references.email-label') }}</label>
              <el-input :placeholder="this.studentInfo.parent.email" disabled="disabled"></el-input>
            </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn result-close" @click="$bvModal.hide('profile-student')">{{
          $t('profile.results.btn-close')
        }}
      </button>
    </b-modal>
    <b-modal id="test-student" hide-footer hide-header centered size="lg">
      <div class="content">
        <div class="profile-student__title">
          {{ $t('profile.children.test-results') }}
          <div class="clearfix bg-blue">
            <div class="pt-4 pt-lg-0">
              <div class="my-results-table-wrapper">
                <div>

                  <div class="results-table table-responsive-md">
                    <el-table
                        class="mb-4"
                        stripe
                        v-loading="loading"
                        :data="results"
                        style="width: 100%">
                      <el-table-column
                          width="250"
                          prop="quiz_title"
                          :label="$t('profile.results.subject')">
                      </el-table-column>
                      <el-table-column
                          prop="result"
                          class="text-center"
                          :label="$t('profile.results.score')">
                      </el-table-column>
                      <el-table-column
                          prop="date"
                          class="text-center"
                          :label="$t('profile.results.date')">
                      </el-table-column>
                      <el-table-column
                          prop="recommendation.text"
                          class="text-center"
                          :label="$t('profile.children.recommendation')">
                      </el-table-column>
                      <el-table-column
                          :label="$t('my-consultations.actions')">
                        <template slot-scope="scope">
                          <div class="result-recomendations">
                            <button @click="showMore(scope.row.user_quiz_id,scope.row.second_step)" class="btn btn-blue">
                              {{ $t('profile.results.view-btn') }}
                            </button>

                            <a v-if="scope.row.recommendation.file" :href="scope.row.recommendation.file" download style="margin-top: 1rem"  class="btn btn-blue">
                              {{ $t('test-results.download-file') }}
                            </a>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <button class="btn result-close" @click="$bvModal.hide('test-student')">{{
          $t('profile.results.btn-close')
        }}
      </button>
    </b-modal>
    <ProfileNavbar :title="$t('profile.children.title')"></ProfileNavbar>
    <div class="mob-menu-title">
      <router-link tag="a" to="/profile-parent" class="mob-menu-back d-none">
        <img src="images/profile/arrow-back.svg">
      </router-link>
      {{ $t('profile.children.title') }}
    </div>
    <div class="clearfix">
      <div class="bg-blue">
        <div class="col-md-12 testing-tabs testing-tabs--mob">
          <div class="tabsMenu">
            <ul class="nav nav-tabs d-flex flex-row">
              <li class="nav-item">
                <a class="nav-link testing-tabs-title text-center" @click.prevent="setActive('add-child')"
                   :class="{ active: isActive('add-child') }" href="#add-child">{{
                    $t("profile.children.add-child-tab")
                  }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link testing-tabs-title text-center" @click.prevent="setActive('added-children')"
                   :class="{ active: isActive('added-children') }"
                   href="#added-children">{{ $t("profile.children.added-children-tab") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="children">
          <div class="children-tabs-block">
            <ul class="nav children-tabs d-flex flex-row">
              <li class="children-nav-item">
                <a class="nav-link parent-tabs-title text-center w-100" @click.prevent="setActive('add-child')"
                   :class="{ active: isActive('add-child') }" href="#add-child">{{
                    $t("profile.children.add-child-tab")
                  }}</a>
              </li>
              <li class="children-nav-item">
                <a class="nav-link parent-tabs-title text-center w-100" @click.prevent="setActive('added-children')"
                   :class="{ active: isActive('added-children') }"
                   href="#added-children">{{ $t("profile.children.added-children-tab") }}</a>
              </li>
            </ul>
          </div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="{ 'active show': isActive('add-child') }" id="add-child">
              <div class="iin-search-title">
                {{ $t('profile.children.add-iin') }}
              </div>

              <div class="form-group mb-0">
                <div class="form-row">
                  <div class="search-block pb-3">
                    <input autocomplete="off" maxlength="12" v-model="iin" type="text" class="form-control search-input"
                           id="input_iin" :placeholder="$t('profile.children.search-iin')">
                    <div class="search-btns">
                      <button @click="clearIin()" class="search-delete-btn">
                        <img width="14" src="/images/profile/close_big.svg">
                      </button>
                      <button type="submit" @click="checkIin()" class="search-btn">
                        <img width="15" src="/images/profile/search-white.svg">
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="search-result-block pt-3">
                <div v-if="!hideRes" class="search-result-title">
                  {{ $t('profile.children.search-result') }}
                </div>

                <div v-if="!hideRes" class="search-result">
                  <div class="search-result-photo">
                    <img class="search-result-photo-img" src="/images/profile/avatar.png">
                  </div>
                  <div class="d-flex flex-row search-result-name">
                    <img width="17" height="17" class="mr-3" src="/images/profile/clip-grey.svg">
                    {{ this.first_name + ' ' + this.last_name + ' ' + this.patrononymic }}
                  </div>
                  <div class="search-result-class">
                    {{ this.class + $t('profile.children.class') }}
                  </div>
                  <div class="search-result-btns">
                    <button @click="addChilden()" class="search-result-btn">
                      {{ $t('profile.children.add-btn') }}
                    </button>
                  </div>
                </div>

                <div v-if="!iinNone" class="text-center search-without-result">
                  <p class="mb-0">{{ $t('profile.children.iin-is-not-available-1') }}</p>
                  <p class="mb-0">{{ $t('profile.children.iin-is-not-available-2') }}</p>
                </div>

                <div class="all-requests">
                  <div class="all-requests-title">
                    {{ $t('profile.children.all-requests') }}
                  </div>
                  <div class="table-responsive-lg mb-0">
                    <table class="table table-striped all-requests-table">
                      <thead>
                      <tr>
                        <th scope="col">{{ $t('profile.children.full-name') }}</th>
                        <th scope="col">{{ $t('profile.children.status') }}</th>
                        <th scope="col">{{ $t('profile.children.class-title') }}</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="child in childRequests"
                          v-bind:class="{ 'confirmed': child.status==1, 'awaiting-confirmation': child.status==2 }">
                        <td scope="row">
                          <!--                                                        <img src="/images/profile/avatar.png" class="all-requests-table-img">-->
                          <div class="all-requests-table-name">

                            <img v-if="child.status==1" width="17" height="17" class="mr-3"
                                 src="/images/profile/clip-blue.svg">
                            <img v-if="child.status==2" width="17" height="17" class="mr-3"
                                 src="/images/profile/clip-grey.svg">
                            {{ child.child.first_name + ' ' + child.child.last_name }}{{
                              child.child.patronymic == null ? '' : ' ' + child.child.patronymic
                            }}
                          </div>
                        </td>
                        <td v-if="child.status==1" class="confirmation-status">{{
                            $t('profile.children.confirmed')
                          }}
                        </td>
                        <td v-if="child.status==2" class="confirmation-status">
                          {{ $t('profile.children.awaiting-confirmation') }}
                        </td>

                        <td v-if="child.child.education" class="all-requests-table-class">
                          {{ child.child.education.class }} {{ $t('profile.children.class') }}
                        </td>

                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :class="{ 'active show': isActive('added-children') }" id="added-children">
              <div id="accordion-added-children">
                <div class="card" v-for="(data, key) in accordionData">
                  <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                    <img class="added-children-img" :src="data.avatar">
                    <h5 class="mb-0">
                      <button class="btn btn-link added-children-name"
                              :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse"
                              :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                        <img width="17" height="17" class="mr-3" src="/images/profile/clip-blue.svg">
                        {{ data.title }}
                      </button>
                    </h5>
                    <div class="card-body card-body--mob-visible">
                      <div>
                        <ul>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showProfile(data.id)">{{ $t('profile.title') }}</div>
                          </li>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showTestResult(data.id)">{{ $t('profile.children.test-results') }}</div>
                          </li>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showConsult(data.id)">
                              {{ $t('profile.children.consultations-result') }}
                            </div>
                          </li>
                          <!--                          <li><span class='list-type'></span><a>Результаты тестирования</a></li>-->
<!--                          <li><span class='list-type'></span><a>{{ $t('children.consultations-result') }}</a></li>-->

                        </ul>

                      </div>
                    </div>
                    <button class="btn-blue added-children-btn">{{ $t('profile.children.view-btn') }}</button>
                  </div>

                  <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse"
                       :aria-labelledby="'heading' + key" data-parent="#accordion-added-children">
                    <div class="card-body p-3 card-body--mob-none">
                      <div>
                        <ul>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showProfile(data.id)">
                              {{ $t('profile.title') }}
                            </div>
                          </li>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showTestResult(data.id)">{{ $t('profile.children.test-results') }}</div>
                          </li>
                          <li><span class='list-type'></span>
                            <div class="button-text" @click="showConsult(data.id)">
                              {{ $t('profile.children.consultations-result') }}
                            </div>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";


export default {
  name: 'Parent',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      schools:'',
      psychData: '',
      studentRegion: '',
      studenParentPhone: '',
      studenParentFio: '',
      studenParentEmail: '',
      studentInstructionLanguage: '',
      studentTestLanguage: '',
      studentEmail: '',
      studentSchool: '',
      studentClass: '',
      studentLocality: '',
      studentCity: '',
      studentInfo: [],
      childRequests: [],
      childrenId: '',
      hideRes: true,
      class: '',
      first_name: '',
      last_name: '',
      patrononymic: '',
      iin: '',
      iinNone: true,
      addChild: [],
      addedChildren: [],
      activeItem: 'add-child',
      search: '',
      accordion: 'collapse0',
      accordionData: [],
      results:[],
    }
  },

  mounted() {

    this.getRequest()


  },
  methods: {
    showTestResult(id){
      this.$http.get(window.API_ROOT + '/api/quizzes/results/'+id)
          .then((res) => {
            if(res.body.items.length==0){
              Vue.toastr({
                message: this.$t('profile.settings.error'),
                description: this.$t('main-page.no-test'),
                type: 'error'
              })
            }
            else{
              this.results = res.body.items
              for (var i=0; i<this.results.length; i++){
                this.results[i].date=this.results[i].date.replace('-', '.')
                this.results[i].date=this.results[i].date.replace('-', '.')
              }

              this.$bvModal.show('test-student');
            }

          })
    },
    showMore(id,step) {

      if(step==null){
        var url = this.$router.resolve({name: 'test-results', query: {'id': id}})
      }
      else {
        var url = this.$router.resolve({name: 'test-results-second', query: {'id': id}})
      }
      window.open(url.href);


      // var url = this.$router.resolve({name: 'test-results', query: {'id': id}})
      //
      // window.open(url.href);
    },
    getSchool(id) {
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
              this.schools = res.body.data;
            }).catch((e) => {
      })

    },
    showProfile(id) {
      this.$http.get(window.API_ROOT + '/api/child/' + id)
          .then((res) => {
            this.studentInfo = res.body.data
            this.$bvModal.show('profile-student');
            this.studentSchool = this.studentInfo.education.school_id
            this.getSchool(this.studentInfo.education.city_id)
            this.studentClass = this.studentInfo.education.class
            this.studentLocality = this.studentInfo.locality_id.name
            this.studentCity = this.studentInfo.city_id
            this.studentCity = this.studentCity.name
            this.studentEmail = this.studentInfo.email
            this.studentTestLanguage = this.studentInfo.education.test_language
            this.studentInstructionLanguage = this.studentInfo.education.instruction_language
            this.studentRegion = this.studentInfo.region_id.name

          })
          .catch((e) => {

          });
    },
    showConsult(id) {
      this.$http.post(window.API_ROOT + '/api/child_request?user_id=' + id + '&status=2')
          .then((res) => {
            if (res.body.data.length >= 1) {
              this.psychData = res.body.data
              this.$bvModal.show('psych-student');
            } else {
              Vue.toastr({
                message: this.$t('profile.settings.error'),
                description: this.$t('main-page.no-psych'),
                type: 'error'
              })
            }
          })
          .catch((e) => {

          });
    },
    getChilds() {
      this.accordionData = []
      for (var x = 0; x < this.childRequests.length; x++) {
        if (this.childRequests[x].child.patronymic == null) {
          this.childRequests[x].child.patronymic=''
        }
        var name = this.childRequests[x].child.first_name + ' ' + this.childRequests[x].child.last_name+ ' ' + this.childRequests[x].child.patronymic

        if (this.childRequests[x].status == 1) {
          this.accordionData.push({
            'avatar': this.childRequests[x].avatar,
            'title': name,
            'id': this.childRequests[x].child.education.user_id,
          });
        }

      }
    },
    getRequest() {
      this.$http.get(window.API_ROOT + '/api/child')
          .then((res) => {
            this.childRequests = res.body.data
            this.getChilds()
          })
          .catch((e) => {

          });

    },
    addChilden() {
      this.$http.post(window.API_ROOT + '/api/child?user_id=' + this.childrenId + '&status_id=2')
          .then((res) => {
            Vue.toastr({
              message: this.$t('profile.settings.success') + '!',
              description: this.$t('profile.settings.request-send'),
              type: 'success'
            });
            this.hideRes = true
            this.clearIin()
            this.getRequest()
          })
          .catch((e) => {

          });
    },
    clearIin() {
      document.getElementById('input_iin').value = "";
      this.iin = ''
    },
    checkIin() {
      this.$http.post(window.API_ROOT + '/api/search?iin=' + this.iin)
          .then((res) => {
            this.iinNone = true
            this.hideRes = false
            this.childrenId = res.body.data.education.user_id
            this.first_name = res.body.data.first_name
            this.last_name = res.body.data.last_name
            if (res.body.data.patronymic == null) {
              this.patrononymic = ''
            } else {
              this.patrononymic = res.body.data.patronymic
            }
            this.class = res.body.data.education.class
          })
          .catch((e) => {
            if (e.status === 422) {
              this.iinNone = false
              this.hideRes = true
            }

            if (e.status === 401) {
              this.$router.push('/')
            }
          });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },
  },
}
</script>
<style>
.button-text {
  cursor: pointer;
  outline: none !important;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.form-group {
  text-align-last: left;
  display: flex;
  flex-direction: column;
}

#profile-student .form-sub-title {
  padding: 26px 0;
}

#profile-student .modal-body,
#psych-student .modal-body {
  padding: 35px;
}

.profile-student__title {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 26px;
  color: #151515;
  text-align: left;
}

.psych-student-table {
  border-bottom: none !important;
  border-top: none !important;
  text-align: left;
  font-size: 13px;
  line-height: 15px;
  color: #2E3A59;
}

.psych-student-table .table-striped tbody tr:nth-of-type(odd) {
  background: #F5F8FA;
}

.psych-student-table .table thead th, .psych-student-table .table th, .table td {
  border-bottom: none !important;
  border-top: none !important;
  text-align: left;
}

@media screen and (max-width: 1300px) and (min-width: 991px) {
  #profile-student .form-sub-title {
    padding: 26px 0;
    height: auto;
  }
}
</style>
